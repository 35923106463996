import React from 'react';
import Layout from '../components/Layout';
import SEO from "../components/SEO"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
      <SEO
        title="404 Not Found"
        description="Woops, that page is missing. Can you check the URL please? Or if you have a question about geothermal heat pumps, you can contact me through the contact page"
        slug='/404/'
      />
      <h1>Not Found</h1>
      <p>I haven’t written this post yet. Will you help me write it?</p>
      <p>Too doo doo doo doo doo doo doo</p>
      </Layout>
    );
  }
}

export default NotFoundPage;
